import React from "react";
import {
  usePlayer,
  usePlayers,
  useRound,
  Slider,
} from "@empirica/core/player/classic/react";
import { Button } from "../components/Button";
import "../../node_modules/@empirica/core/dist/player-classic-react.css";

export function Judge() {
  const player = usePlayer();
  const players = usePlayers();
  const round = useRound();
  const partner = players.filter((p) => p.id !== player.id)[0];

  const stimID = round.get("stimuliID");
  const numRows = round.get("numRows");
  const numCols = round.get("numCols");
  const winConds = round.get("winConds");

  const judgmentTask = round.get("judgmentTask");

  player.round.set("stimuliID", stimID)

  console.log("judgment task: ", judgmentTask);

  function handleChange(e) {
    player.round.set("judgment", e.target.valueAsNumber);
  }

  function handleChangeAdvantage(e) {
    player.round.set("judgmentAdvantage", e.target.valueAsNumber);
  }

  function handleChangeDraw(e) {
    player.round.set("judgmentDraw", e.target.valueAsNumber);
  }

  console.log("in judgment!");

  const funSliderLabels = [
    "The least fun of this class<br></br>of grid-based game",
    "Neutral",
    "The most fun of this class<br></br>of grid-based game",
  ];

  const advantageSliderLabels = [
    "First player<br></br>definitely going to <strong>lose</strong>",
    "Equally likely to<br></br><strong>win or lose</strong>",
    "First player<br></br>definitely going to <strong>win</strong>",
  ];

  const drawSliderLabels = [
    "Impossible to<br></br>end in a draw",
    "Equally likely to<br></br>end in a draw or not",
    "Definitely going to<br></br>end in a draw",
  ];

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        <strong>In this game,</strong> you will play on a{" "}
        <strong>
          {numRows} x {numCols} board
        </strong>
        .
      </p>
      <p style={{ textAlign: "center" }}>
        The rules for this game are: <strong>{winConds}</strong> 
      </p>
      <br></br>

      

      {judgmentTask === "how_fun" && (
        <>
          <p style={{ textAlign: "center" }}>
            <strong>Question: </strong>How fun is this game?
          </p>
          <br />
          <br />
          <div
            style={{
              margin: "20px 0",
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Slider
              value={player.round.get("judgment")}
              onChange={handleChange}
              max={100}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                textAlign: "center",
              }}
            >
              {funSliderLabels.map((label, index) => (
                <span
                key={index}
                style={{
                  flex: 1,
                  minWidth: "30%",
                  padding: "0 10px",
                  lineHeight: "0.8", // tighten line breaks
                  ...(index === 0 && { transform: "translateX(-100px)" }), // Move the leftmost label
                  ...(index === drawSliderLabels.length - 1 && { transform: "translateX(100px)" }), // Move the rightmost label
                }}
                dangerouslySetInnerHTML={{ __html: label }}
              ></span>
              ))}
            </div>
          </div>
        </>
      )}

      {judgmentTask === "advantage" && (
        <>
          <p style={{ textAlign: "center" }}>
            <strong>Question 1: </strong>If the game{" "}
            <i>does not end in a draw</i>, assuming both players play
            reasonably, how likely is it that the first player is going to win (
            <i>not draw</i>)
          </p>
          <br />
          <br />
          <div
            style={{
              margin: "20px 0",
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Slider
              value={player.round.get("judgmentAdvantage")}
              onChange={handleChangeAdvantage}
              max={100}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                textAlign: "center",
              }}
            >
              {advantageSliderLabels.map((label, index) => (
                <span
                key={index}
                style={{
                  flex: 1,
                  minWidth: "30%",
                  padding: "0 10px",
                  lineHeight: "0.8", // tighten line breaks
                  ...(index === 0 && { transform: "translateX(-100px)" }), // Move the leftmost label
                  ...(index === drawSliderLabels.length - 1 && { transform: "translateX(100px)" }), // Move the rightmost label
                }}
                dangerouslySetInnerHTML={{ __html: label }}
              ></span>
              ))}
            </div>
          </div>
          <br></br>
          <p style={{ textAlign: "center" }}>
            <strong>Question 2: </strong> Assuming both players play reasonably,
            how likely is the game to end in a draw?
          </p>
          <br />
          <br />
          <div
            style={{
              margin: "20px 0",
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Slider
              value={player.round.get("judgmentDraw")}
              onChange={handleChangeDraw}
              max={100}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                textAlign: "center",
              }}
            >
              {drawSliderLabels.map((label, index) => (
  <span
    key={index}
    style={{
      flex: 1,
      minWidth: "30%",
      padding: "0 10px",
      lineHeight: "0.8", // tighten line breaks
      ...(index === 0 && { transform: "translateX(-100px)" }), // Move the leftmost label
      ...(index === drawSliderLabels.length - 1 && { transform: "translateX(100px)" }), // Move the rightmost label
    }}
    dangerouslySetInnerHTML={{ __html: label }}
  ></span>
))}
            </div>
          </div>
        </>
      )}

      <div style={{ textAlign: "center" }}>
        <Button handleClick={() => player.stage.set("submit", true)}>
          Submit Response
        </Button>
      </div>
    </div>
  );
}
